<script setup lang="ts">
import CellLoading from '/~/extensions/bank-file-upload/components/common/cell-loading.vue'
import { formatDollar } from '/~/utils/format/money'
import { useLocalization } from '/~/composables/localization'
import PayToRowStatus from './pay-to-row-status.vue'

export type Row = {
  accountName: string
  accountNumber: string
  bsb: string
  reference: string
  amount: string
  payee: {
    abn: string
  }
}

defineProps<{
  row: Row
  loading: boolean
}>()

const { formatBsb } = useLocalization()
</script>

<template>
  <div class="flex-col">
    <div class="flex min-h-16 w-full items-center text-sm">
      <div class="w-64 px-5">
        <cell-loading :loading="loading">
          {{ row.accountName }}
        </cell-loading>
      </div>
      <div class="w-64 px-5 text-center">
        <cell-loading :loading="loading">
          {{ formatBsb(row.bsb) }},
        </cell-loading>
        <cell-loading :loading="loading">
          {{ row.accountNumber }}
        </cell-loading>
      </div>
      <div class="ml-auto w-64 whitespace-pre-wrap px-5 text-center">
        <cell-loading :loading="loading">
          {{ row.reference }}
        </cell-loading>
      </div>
      <div class="w-44 px-5 text-center">
        <cell-loading :loading="loading">
          {{ formatDollar(row.amount) }}
        </cell-loading>
      </div>
    </div>
    <pay-to-row-status
      v-if="!loading"
      :row="row"
      class="-mb-px flex w-full items-center space-x-1 border-x-0 px-5 py-1 text-left"
    ></pay-to-row-status>
  </div>
</template>
