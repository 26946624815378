<script setup lang="ts">
import { onMounted } from 'vue'
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import Controls from '/~/extensions/bank-file-upload/components/common/controls.vue'
import Notification from '/~/extensions/bank-file-upload/components/common/notification.vue'
import Pagination from '/~/extensions/bank-file-upload/components/common/pagination.vue'
import UploadedFile from '/~/extensions/bank-file-upload/components/common/uploaded-file.vue'
import LayoutMain from '/~/extensions/bank-file-upload/layouts/main.vue'
import PendingPayeesTable from '/~/extensions/payroll/components/pending-payees/table/pending-payees-table.vue'
import { formatDollar } from '/~/utils/format/money'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import {
  usePayrollOrder,
  usePayrollOrderPendingPayees,
  usePayrollOrderStatusHandler,
} from '/~/composables/batch-order/payroll'
import { useLocalization } from '/~/composables/localization'

const { pendingPayees, goToCheckout } = usePayrollOrderPendingPayees()
const { translate } = useLocalization()

const { payrollOrder, onRemoveBatchFile, onCancelBatchOrder, fetchBatchOrder } =
  usePayrollOrder()
const { payrollOrderStatusHandler } = usePayrollOrderStatusHandler()

const tableLabels = [
  { name: 'Account name', class: 'px-5 w-64' },
  {
    name: translate('bankAccount.detailsTitle'),
    class: 'px-5 w-64 text-center',
  },
  { name: 'Reference*', class: 'ml-auto px-5 w-64 text-center' },
  { name: 'Amount', class: 'px-5 w-44 text-center' },
]

const cancelButtonText = 'Cancel'
const continueButtonText = 'Continue'

async function init() {
  await fetchBatchOrder(payrollOrder.number)
  await pendingPayees.load(1)
}

onMounted(() => {
  init()
  emitter.on('notifications:batch-order', async (event) => {
    const { batchOrderId, batchOrderStatus } = event

    if (
      batchOrderId === payrollOrder.fileUploadId &&
      batchOrderStatus === 'payees_configuring'
    ) {
      init()
    }
  })
})
</script>

<template>
  <layout-main>
    <component
      :is="ui.mobile ? BaseAsidePage : 'div'"
      :title="ui.mobile && 'Upload file details'"
      :no-padding="ui.mobile && true"
      :back="ui.mobile && { name: 'home' }"
      class="h-full"
    >
      <div class="flex h-full flex-col p-5 lg:px-12 lg:py-8">
        <notification
          v-if="!payrollOrder.isStatusPayeesSynced"
          class="mb-8"
          icon="heroicons/solid/information-circle"
        >
          Please wait while we prepare new payees
          <span v-if="payrollOrder.totalTransactionsCount > 0">
            - {{ payrollOrder.newTransactionsCount }}/{{
              payrollOrder.totalTransactionsCount
            }}
            completed.
          </span>
        </notification>

        <div class="mb-8">
          <h1
            v-if="!ui.mobile"
            class="mb-8 text-2xl leading-none text-eonx-neutral-800"
          >
            Payroll details
          </h1>
          <div class="mt-8 flex space-x-5 divide-x">
            <uploaded-file
              :batch-order="payrollOrder"
              :on-cancel-order="onRemoveBatchFile"
            />
            <div
              class="space-x-1 truncate pl-5 text-xl font-bold text-eonx-neutral-800"
            >
              Total payments: {{ pendingPayees.pagination.totalItems }}
            </div>
            <div
              class="space-x-1 truncate pl-5 text-xl font-bold text-eonx-neutral-800"
            >
              Total amount to be processed:
              {{ formatDollar(payrollOrder.initialAmount) }}
            </div>
          </div>
          <div class="mt-4">
            *Please note that the reference (sent to payee) will be limited to 9
            characters. For queries, contact support.
          </div>
        </div>

        <pending-payees-table
          :labels="tableLabels"
          :rows="pendingPayees.transactionList.list"
          :loading="pendingPayees.loader.loading"
        />

        <pagination
          v-if="pendingPayees.pagination.totalPages > 1"
          class="mt-8"
          :current-page="pendingPayees.pagination.currentPage"
          :total-pages="pendingPayees.pagination.totalPages"
          @selectPage="pendingPayees.load($event)"
        />

        <controls
          class="sticky bottom-0 mt-8 w-full bg-eonx-neutral-50"
          :is-continue-disabled="!payrollOrder.isStatusPayeesSynced"
          :is-continue-loading="
            pendingPayees.loader.loading ||
            payrollOrderStatusHandler.batchOrderLoader.loading
          "
          :cancel-text="cancelButtonText"
          :continue-text="continueButtonText"
          @cancel="onCancelBatchOrder"
          @continue="goToCheckout"
        />
      </div>
    </component>
  </layout-main>
</template>
