import { usePayrollOrder } from '/~/composables/batch-order/payroll'
import { usePayrollOrderStatusHandler } from './use-payroll-order-status-handler'
import { BatchOrderPendingPayees } from '../core/BatchOrderPendingPayees'

export function usePayrollOrderPendingPayees() {
  const { payrollOrder } = usePayrollOrder()

  const { payrollOrderStatusHandler } = usePayrollOrderStatusHandler()

  const pendingPayees = new BatchOrderPendingPayees({
    url: `/v3/batch-orders/${payrollOrder.number}/transactions`,
    perPage: '10',
  })

  async function goToCheckout() {
    payrollOrderStatusHandler.setReadyToPay()
    payrollOrderStatusHandler.startCheckingLoop()
  }

  return {
    pendingPayees,
    goToCheckout,
  }
}
