<script setup lang="ts">
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { Row } from './table-row.vue'

const props = defineProps<{
  row: Row
}>()

const statusClass = computed(() => {
  if (props.row.statusPending) {
    return 'bg-orange-50 border-orange-700 text-orange-700'
  }
  if (props.row.isLongReference) {
    return 'bg-blue-50 border-blue-700 text-blue-700'
  }
  return 'hidden'
})

const statusIcon = computed(() => {
  if (props.row.statusPending) {
    return 'heroicons/mini/clock'
  }
  if (props.row.isLongReference) {
    return 'heroicons/mini/information-circle'
  }
  return null
})

const statusMessage = computed(() => {
  if (props.row.statusPending) {
    return 'Preparing payee'
  }
  if (props.row.isLongReference) {
    return 'Lodgement reference is longer than what is supported. Should you wish to proceed as it is, it will be trimmed to 9 characters.'
  }
  return null
})
</script>

<template>
  <div :class="['border text-sm', statusClass]">
    <base-icon class="flex-shrink-0" :svg="statusIcon" :size="16" />
    <span>{{ statusMessage }}</span>
  </div>
</template>
